import TYPES from '@/types';
import { v4 as uuid } from 'uuid';

// Application
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  AgreementEntity,
} from '@/modules/my-investment/agreements/domain/entities/agreement-entity';
import Inject from '@/modules/shared/domain/di/inject';
import {
  CustomerAgreementEntity,
} from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsKuspitAccountViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  i18n_namespace = 'components.contract-savings.before-start.kuspit_account';

  accept_agreements = false;

  small_screen: null | boolean = null;

  exists_step = false;

  step_name = 'on_boarding_kuspit_terms_and_conditions';

  investment_provider_id = '';

  investment_provider_name = 'kuspit';

  is_loading = true;

  private user_agreement: CustomerAgreementEntity = {
    accepted_on: null,
    agreement_type_id: '',
    was_accepted: false,
    id: '',
    description: '',
  };

  private on_boarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  url_conditions_of_service = `${process.env.VUE_APP_S3_LEGAL_URL}/Kuspit_Condiciones_de_Servicio.pdf`;

  url_notice_of_privacy = `${process.env.VUE_APP_S3_LEGAL_URL}/Kuspit_Normas_de_Privacidad.pdf`;

  toggleUserAgreement = (was_accepted: boolean) => {
    this.user_agreement.was_accepted = was_accepted;
    this.user_agreement.accepted_on = this.datetime_value.create();
  };

  setUserAgreementData = (new_agreements: Array<AgreementEntity>) => {
    if (new_agreements.length > 0) {
      const agreement = new_agreements.find(
        (item) => item.name === 'terms_and_conditions_kuspit',
      );
      if (agreement) {
        this.user_agreement.id = uuid();
        this.user_agreement.agreement_type_id = agreement.id;
        this.user_agreement.description = agreement.description;
      }
    }
  };

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      // eslint-disable-next-line max-len
      if (this.user_agreement.agreement_type_id === search_step.payload.agreements[0].agreement_type_id) {
        this.accept_agreements = this.on_boarding_step.payload.agreements[0].was_accepted;
      }
    }
  };

  async saveStep() {
    this.is_loading = true;
    try {
      if (this.exists_step) {
        this.on_boarding_step.payload = {
          agreements: [this.user_agreement],
        };
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        this.on_boarding_step.id = uuid();
        this.on_boarding_step.current_step = this.step_name;
        this.on_boarding_step.investment_provider_id = this.investment_provider_id;
        this.on_boarding_step.payload = {
          agreements: [this.user_agreement],
        };
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.searchInvestmentProvidersQuery.execute();
    this.setInvestmentProviderId(investment_providers);

    const agreements = await this.searchAgreementsAsyncQuery.execute();
    this.setUserAgreementData(agreements);

    const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
    this.verifyStep(steps);

    this.is_loading = false;
  }
}
