
































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ContractSavingsKuspitAccountViewModel
  from '@/vue-app/view-models/components/contract-savings/before-start/contract-savings-kuspit-account-view-model';

// Domain
import { AgreementEntity } from '@/modules/my-investment/agreements/domain/entities/agreement-entity';

@Component({ name: 'ContractSavingsKuspitAccount' })
export default class ContractSavingsKuspitAccount extends Vue {
  contract_savings_kuspit_account_view_model = Vue.observable(
    new ContractSavingsKuspitAccountViewModel(),
  );

  @Watch('contract_savings_kuspit_account_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('contract_savings_kuspit_account_view_model.agreements')
  onAgreementsChange(new_agreements: Array<AgreementEntity>) {
    this.contract_savings_kuspit_account_view_model.setUserAgreementData(new_agreements);
  }

  @Watch('contract_savings_kuspit_account_view_model.accept_agreements')
  onAcceptChange(new_accept: boolean) {
    this.contract_savings_kuspit_account_view_model.toggleUserAgreement(new_accept);
  }

  async created() {
    await this.contract_savings_kuspit_account_view_model.initialize();
    this.$emit('loadingInfo', this.contract_savings_kuspit_account_view_model.is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const save_step = await this.contract_savings_kuspit_account_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }
}
